import { FaLinkedin } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa6";

export const AboutMe = () => {
  const lastUpdated = "January 2025";

  return (
    <div className="flex flex-col w-full h-full px-8 py-40 overflow-y-auto font-zenloop">
      <div className="max-w-2xl mx-auto">
        <h1 className="mb-2 text-4xl text-custom-orange">About Me</h1>
        <p className="mb-8 font-sans text-sm italic">
          Last updated: {lastUpdated}
        </p>

        <div className="space-y-6 font-sans text-lg">
          <p>
            Hey! I'm Erin Beachkofski, a creative technologist based in San
            Francisco. My days are spent as a software developer, but behind the
            scenes, I'm always scheming up a new project. Sometimes it's
            something in the physical world, and sometimes it's something in the
            digital world.
          </p>

          <p>
            I'm on a mission to make life more playful and interesting, which
            leads me down all sorts of creative paths. Currently, you might find
            me:
          </p>

          <ul className="pl-6 space-y-2 list-disc">
            <li>
              Painting with watercolors (I love taking my travel set to Golden
              Gate Park and paint in the botanical garden 🌼)
            </li>
            <li>
              Giving thrifted items new life (repurposing frames, finding free
              canvases on the street)
            </li>
            <li>
              Sand casting silver into unique jewelry pieces in my backyard
            </li>
            <li>
              Creating indie software projects and hiding them around San
              Francisco like digital easter eggs
            </li>
          </ul>

          <p className="text-sm italic">
            * this list changes pretty frequently 🙈 I'm a serial hobbyist -
            consider this a snapshot of my current creative adventures rather
            than a complete portfolio.
          </p>

          <p>
            This site serves as both my gallery and my motivation to keep
            creating. It's a space where my tech background meets my artistic
            endeavors, and I'm excited to share both the finished pieces and the
            journey of making them.
          </p>

          <p className="mt-8">- Erin</p>
        </div>

        <div className="mt-8">
          <p className="font-sans">You can learn more about me on:</p>
          <div className="flex flex-row justify-start gap-4 mt-2">
            <a
              href="https://github.com/erinbeachkofski"
              target="_blank"
              rel="noreferrer"
              className="text-custom-orange"
            >
              <FaGithub size={36} />
            </a>
            <a
              href="https://www.linkedin.com/in/erinbeachkofski/"
              target="_blank"
              rel="noreferrer"
              className="text-custom-orange"
            >
              <FaLinkedin size={36} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
