import "./styles.css";
import DrawCanvasWrapper from "./DrawCanvas/DrawCanvasWrapper";
import { drawCanvasSketch } from "./DrawCanvas/drawCanvasSketch";
import { useEffect, useState } from "react";
import { GuestLineData } from "./models";
import { AboutMe } from "./screens/AboutMe";
import { Projects } from "./screens/Projects";
import { motion, AnimatePresence } from "framer-motion";

type Page = "home" | "about" | "projects";

const App = () => {
  const brushType = "flower";
  const [guestLines, setGuestLines] = useState<GuestLineData[]>([]);
  const [currentPage, setCurrentPage] = useState<Page>("home");

  const renderPage = () => {
    return (
      <AnimatePresence mode="wait">
        <motion.div
          key={currentPage}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="w-full h-full"
        >
          {(() => {
            switch (currentPage) {
              case "projects":
                return <Projects />;
              case "about":
                return <AboutMe />;
              default:
                return (
                  <div className="flex w-full h-full">
                    <DrawCanvasWrapper
                      sketch={drawCanvasSketch}
                      sketchProps={{
                        brushType: brushType,
                        guestLines: guestLines,
                      }}
                    />
                  </div>
                );
            }
          })()}
        </motion.div>
      </AnimatePresence>
    );
  };

  useEffect(() => {
    setGuestLines([]);
  }, [currentPage]);

  // Disable scrolling
  useEffect(() => {
    const preventDefault = (e: TouchEvent) => {
      if (
        !(
          e.target instanceof HTMLElement &&
          (e.target.tagName === "BUTTON" || e.target.closest("button"))
        )
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchstart", preventDefault, { passive: false });
    document.addEventListener("touchmove", preventDefault, { passive: false });
    document.addEventListener("touchend", preventDefault, { passive: false });

    return () => {
      document.removeEventListener("touchstart", preventDefault);
      document.removeEventListener("touchmove", preventDefault);
      document.removeEventListener("touchend", preventDefault);
    };
  }, []);

  return (
    <div className="flex flex-col w-screen h-screen">
      <div className="absolute top-0 z-0 flex flex-row justify-between w-full h-48 gap-4 px-12 py-12">
        {currentPage !== "home" && (
          <div className="h-48 gap-4 -m-8">
            <img
              src={require("./assets/images/erin_logo.png")}
              className="object-cover h-auto px-8 py-8 w-52 hover:opacity-50"
              alt="erin logo"
              onClick={() => setCurrentPage("home")}
            />
          </div>
        )}
        <div className="flex justify-end w-full gap-2">
          <button
            onClick={() => setCurrentPage("projects")}
            className={`flex items-center justify-center h-12 px-8 py-4 text-2xl uppercase transition-colors duration-300 rounded-full font-zenloop ${
              currentPage === "projects"
                ? "bg-custom-orange text-white"
                : "bg-transparent text-custom-orange hover:bg-custom-orange hover:text-white"
            }`}
          >
            projects
          </button>
          <button
            onClick={() => setCurrentPage("about")}
            className={`flex items-center justify-center h-12 px-8 py-4 text-2xl uppercase transition-colors duration-300 rounded-full font-zenloop ${
              currentPage === "about"
                ? "bg-custom-orange text-white"
                : "bg-transparent text-custom-orange hover:bg-custom-orange hover:text-white"
            }`}
          >
            about me
          </button>
        </div>
      </div>
      {renderPage()}
    </div>
  );
};

export default App;
