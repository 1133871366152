export const Projects = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full gap-8 font-zenloop">
      <div className="text-6xl">👩‍🎨</div>
      <h1 className="text-4xl font-bold text-custom-orange">Artist at work</h1>
      <p className="font-sans text-xl text-gray-600">
        Making some finishing touches...
      </p>
    </div>
  );
};
